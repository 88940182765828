import React, { useEffect } from "react"
import { withRouter } from "next/router"
import { Box, Flex, useTheme } from "@chakra-ui/react"
import InfiniteScroll from "react-infinite-scroller"
import UserFeedCard from "./UserFeedCard"
import FeedItem from "./FeedItem"
import EmptyFeed from "./EmptyFeed"
import GradeContext from "../../context/GradeContext"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { GYM_DATA_OPT_IN } from "../../config/feedItemTypes"
import { getQueryParam } from "../../utilities/queryParams"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Container from "../../common/components/Container"
import useColors from "../../common/hooks/useColors"

const Feed = (props) => {
  const { blueColor } = useColors()
  const { loadMoreItems, loggedInUser, feedItems, hasMore, loading, router } =
    props

  useEffect(() => {
    if (!loading && hasMore && feedItems?.length < 6) {
      loadMoreItems()
    }
  }, [feedItems?.length, hasMore, loadMoreItems, loading])

  if (loading && !feedItems) {
    return <Container />
  }
  const filteredFeedItems = (feedItems || []).filter((item) => {
    return (
      (!item?.gym?.isHidden &&
        !item?.session?.gym?.isHidden &&
        item.eventType !== GYM_DATA_OPT_IN) ||
      loggedInUser?.isAdmin ||
      loggedInUser.gymsFollowing.includes(item.gym?._id)
    )
  })
  const isShowingFull = getQueryParam("isShowingFullFeed", router) === "true"
  return (
    <Container>
      <GradeContext.Consumer>
        {(gradeType) => (
          <Box mb={200} display="flex" flexDirection={["column", "row", "row"]}>
            <Box minWidth={270}>
              <UserFeedCard loggedInUser={loggedInUser} router={router} />
            </Box>
            <Box
              maxWidth={["none", "calc(100% - 280px);", "calc(100% - 280px);"]}
            >
              <div className={isShowingFull ? "no-pointer" : ""}>
                {filteredFeedItems.length === 0 ? (
                  <EmptyFeed loggedInUser={loggedInUser} />
                ) : (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    hasMore={hasMore}
                    loadMore={loadMoreItems}
                    threshold={500}
                    loader={
                      <Flex justify={"center"} my={12}>
                        <FontAwesomeIcon
                          color={blueColor}
                          icon={faSpinnerThird}
                          spin
                          size="3x"
                        />
                      </Flex>
                    }
                  >
                    {filteredFeedItems.map((feedItem) => {
                      return (
                        <FeedItem
                          key={feedItem._id}
                          loggedInUser={loggedInUser}
                          gradeType={gradeType}
                          feedItem={feedItem}
                        />
                      )
                    })}
                  </InfiniteScroll>
                )}
              </div>
            </Box>
          </Box>
        )}
      </GradeContext.Consumer>
    </Container>
  )
}

Feed.propTypes = {}

export default withRouter(Feed)
